import { useEffect } from 'react';

import { planhatTraker } from 'utils/tags';
import { useSelector } from 'utils/redux';


const isNotProd =
  window.location.href.includes('staging') ||
  window.location.href.includes('localhost') ||
  window.location.href.includes('lab');

const getNow = () => new Date().getTime();
const ONE_DAY = 24 * 60 * 60 * 1000;

const useTrackingLogin = () => {
  const sfId = useSelector(({ user }) => user?.profile?.company?.sfId);

  useEffect(() => {
    if (!window.trk_ext || isNotProd) return;

    const script = document.createElement('script');
    script.type = "text/javascript";
    script.innerHTML = `
      window.TRK = {
        domain: 'kolsquare.com',
        teamblue: 1,
        ready: 0
      };
      window.trk_ext = window.trk_ext || {};
      window._dadaq = window._dadaq || [];
      window.trk_ext.trkC_bill = "${sfId}";
    `;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [sfId]);

  useEffect(() => {
    const checkAndRunTask = () => {
      const lastRun = localStorage.getItem('lastDailyTaskRun');
      const now = getNow();

      if (!lastRun || (now - Number(lastRun) >= ONE_DAY)) {
        if (!window.trk_ext) {
          window.trk_ext = {};
        }
        if (sfId) {
          window.trk_ext.trkC_bill = sfId
        }
        planhatTraker({
          action: 'Daily activity',
        }).then(() => localStorage.setItem('lastDailyTaskRun', now.toString()))
      }
    };

    checkAndRunTask();

    const interval = setInterval(
      () => checkAndRunTask(),
      60000
    );

    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, []);
};

export default useTrackingLogin;
