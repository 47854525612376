import querystring from 'jquery-param';
import { tokenTypes } from 'constants/auth';

export const translateStatusCode = (statusCode) => {
  switch (statusCode) {
    case 601: return 'expiredToken';
    case 602: return 'unconfirmed';
    case 603: return 'forbidden';
    case 604: return 'isAlreadyConfirmed';
    case 605: return 'emailNotFound';
    case 607: return 'wrongPassword';
    case 608: return 'notActive';
    case 609: return 'alreadyUsed';
    case 610: return 'nonActivated';
    case 611: return 'passwordTooShort';
    case 701: return 'connectionError';
    case 801: return 'tokenNotFound';
    case 803: return 'alreadyUsed';
    case 'incorrect_code': return 'incorrect_code';
    default: return 'unknown';
  }
}

export const verifyToken = {
  toApi: ({ tokenType, token }) => querystring({
    token_type: tokenType,
    token,
  }),
  fromApi: (response) => {
    if (response?.error_code) return {
      error: translateStatusCode(response?.error_code),
    };
    return {};
  }
};

export const confirmAccount = {
  toApi: ({ token }) => querystring({
    [tokenTypes.CONFIRMATION]: token,
  }),
  fromApi: (response) => {
    if (response?.error_code) return {
      error: translateStatusCode(response?.error_code),
    };
    return {};
  }
};

export const activateAccount = {
  fromApi: (response) => {
    if (response?.error_code) return {
      error: translateStatusCode(response?.error_code),
    };
    return {};
  }
};

export const resetPassword = {
  fromApi: (response) => {
    if (response?.error_code) return {
      error: translateStatusCode(response?.error_code),
    };
    return {};
  }
};

export const login = {
  fromApi: (response) => {
    if (response?.error_code)
      return {
        error: translateStatusCode(response?.error_code),
      };
    return {
      userId: response?.user_id,
      hasCommunityAccess: response?.has_community_access,
      otp_user_id: response?.otp_user_id,
      otp_method: response?.otp_method,
      phone: response?.phone,
      must_enter_phone: response?.must_enter_phone || false,
      hasCookie: response?.hasCookie,
    };
  },
};

export const requestPasswordReset = {
  fromApi: (response) => {
    if (response?.error_code) return {
      error: translateStatusCode(response?.error_code),
    };
    return {};
  }
};
