import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl-phraseapp';
import Divider from '@mui/material/Divider';

import { Input, Label, Button3, Loader } from 'kolkit';

import { useDispatch } from 'utils/redux';
import * as authActions from 'actions/auth';
import * as uiActions from 'actions/ui';
import { pageNames } from 'constants/auth';
import useAuthChecker from 'utils/hooks/useAuthChecker';
import SetupDoubleAuthenticationModal from 'components/modules/DoubleAuthentication/SetupDoubleAuthenticationModal'

import AuthHeader from '../AuthHeader';
import AzureSsoButton from '../AzureSsoButton';
import ErrorMessage from '../ErrorMessage';

import styles from '../Auth.module.scss';

const authCheckingStatuses = {
  CHECKING: 'checking',
  CHECKED: 'checked',
};

const Login = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const authChecker = useAuthChecker();

  const [state, setState] = useState({
    authCheckingStatus: authCheckingStatuses.CHECKING,
    email: '',
    password: '',
    loading: false,
    errorCode: ''
  });

  useEffect(
    () => {
      (async () => {
        let hasValidAuthToken;
        if (state.authCheckingStatus === authCheckingStatuses.CHECKING) {
          hasValidAuthToken = await authChecker();
          if (hasValidAuthToken) authActions.goToAppHomePage(); // Home page
        }
        dispatch(uiActions.setLoading(false));
        setState(p => ({
          ...p,
          loading: hasValidAuthToken,
          authCheckingStatus: authCheckingStatuses.CHECKED
        }));
      })();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authChecker, state.hasAuthToken]
  );

  const handleChange = useCallback(({ name, value }) => {
    setState(prevState => ({
      ...prevState,
      [name]: value,
      errorCode: '',
    }));
  }, []);

  const handleLogin = useCallback(
    async e => {
      e.preventDefault();
      setState(prevState => ({
        ...prevState,
        loading: true,
        errorCode: ''
      }));
      const { error } = await dispatch(authActions.login({
        email: state.email,
        password: state.password,
      }));
      setState(prevState => ({
        ...prevState,
        loading: false,
        errorCode: error || ''
      }));
    },
    [dispatch, state.email, state.password]
  );

  if (state.authCheckingStatus === authCheckingStatuses.CHECKING) {
    return (
      <div className={styles.container}>
        <AuthHeader title={intl.formatMessage({ id: 'auth.login.title' })} />
        <Loader />
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <AuthHeader title={intl.formatMessage({ id: 'auth.login.title' })} />
      <form onSubmit={handleLogin}>
        <div className={styles.fields}>
          <Label
            label={intl.formatMessage({ id: 'auth.login.email' })}
            id="auth-login-email-label"
          >
            <Input
              onChange={handleChange}
              placeholder={intl.formatMessage({ id: 'auth.login.email' })}
              size="big"
              type="email"
              name="email"
              value={state.email}
              disabled={state.loading}
              error={!!state.errorCode}
              fullWidth
            />
          </Label>
          <Label
            label={intl.formatMessage({ id: 'auth.login.password' })}
            id="auth-login-password-label"
          >
            <Input
              onChange={handleChange}
              placeholder={intl.formatMessage({ id: 'auth.login.password' })}
              size="big"
              type="password"
              name="password"
              value={state.password}
              disabled={state.loading}
              error={!!state.errorCode}
              fullWidth
            />
          </Label>
          {state.errorCode && (
            <ErrorMessage context="login" errorCode={state.errorCode} />
          )}
          <Button3
            fullWidth
            disabled={state.loading || !state.email || !state.password}
            size="large"
            type="submit"
            label={intl.formatMessage({
              id: `auth.login.${state.loading ? 'loading' : 'button'}`,
            })}
          />
        </div>
        <div className={styles.askIfPasswordForgotten}>
          <Link to={{ search: `?type=${pageNames.REQUEST_PASSWORD_RESET}` }}>
            <FormattedMessage id="auth.login.askIfPasswordForgotten" />
          </Link>
        </div>
      </form>
      <Divider textAlign="center" className={styles.divider}>
        {intl.formatMessage({ id: 'global.label.or' })}
      </Divider>
      <AzureSsoButton type='login' />
      <SetupDoubleAuthenticationModal />
    </div>
  );
};

export default Login;
